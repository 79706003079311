import { useEffect } from 'react';

const App = () => {
  useEffect(() => {
    setTimeout(() => {
       window.location.href = './code-scanner.html';
    }, 0);
  }, []);

  return <div/>;
}

export default App;
